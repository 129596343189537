








































































































































































import _ from "lodash"
import { Component, Vue, Watch } from "vue-property-decorator"
import { Getter } from "vuex-class"
import { debounce } from "lodash-decorators"
import { cx } from "@/types"
import EmptyState from "@/components/EmptyState.vue"
import ScrollFab from "@/components/ScrollFab.vue"
import EngagementService from "@/services/engagement"
import AppService from "@/services/app"

@Component({
  name: "statustracker",
  components: {
    EmptyState,
    ScrollFab,
  },
})
export default class StatusTracker extends Vue {
  private readonly engagementService = new EngagementService()
  private readonly appService = new AppService()
  loading: boolean = true
  projectCount: number = 0
  projects: Array<cx.StatusTracker.Project> = []
  displayProjects: Array<cx.StatusTracker.Project> = []
  states: Array<string> = []
  statusIds: Array<number> = []
  limit: number = 20
  offset: number = 0
  filterSets: object = {
    type: [],
    sensusName: null,
    customerName: null,
    distributorName: null,
  }
  filters: cx.StatusTracker.IFilters = {
    referenceNumber: "",
    type: "",
    accountName: "",
    state: [],
    sensusName: "",
    varName: "",
  }

  get statesExist(): boolean {
    return _.isArray(this.states) && _.size(this.states) > 0
  }

  get isFiltered() {
    return !_.every(_.map(_.values(this.filters), _.isEmpty))
  }

  get queryFilters() {
    let filters = _.clone(this.filters)
    if (!_.isArray(filters.state) || _.size(filters.state) <= 0) {
      filters.state = _.clone(this.states)
    }
    return filters
  }

  @Getter("statustracker/getFilters")
  storeFilters!: cx.StatusTracker.IFilters

  @Watch("filters", { deep: true })
  @debounce(500)
  async handleFilters(filters: cx.StatusTracker.IFilters) {
    this.loading = true
    if (!_.isEqual(this.filters, this.storeFilters)) {
      this.offset = 0
      this.$store.commit("statustracker/updateFilters", this.filters)
    }
    await this.updateProjects()
    this.loading = false
  }

  async loadMore() {
    if (_.size(this.projects) < this.projectCount) {
      this.loading = true
      this.offset += this.limit
      this.projects = _.concat(
        this.projects,
        this.buildProjects(await this.engagementService.getProjects(this.queryFilters, this.limit, this.offset))
      )
      this.updateDisplayProjects()
      this.loading = false
    }
  }

  updateDisplayProjects() {
    if (!_.isNil(this.projects)) {
      this.displayProjects = _.filter(this.projects, (project: cx.StatusTracker.Project) => {
        return !(
          (!_.isEmpty(this.filters.sensusName) && _.isNil(project.user.name)) ||
          (!_.isEmpty(this.filters.varName) && _.isNil(project.contacts.distributor.name))
        )
      })
    }
  }

  async updateProjects() {
    this.engagementService.getProjectsCount(this.queryFilters).then((count: number) => {
      this.projectCount = count
    })
    this.projects = this.buildProjects(
      await this.engagementService.getProjects(this.queryFilters, this.limit, this.offset)
    )
    this.updateDisplayProjects()
  }

  buildProjects(projects: Array<cx.StatusTracker.Project>): Array<cx.StatusTracker.Project> {
    return _.map(projects, (project: cx.StatusTracker.Project) => {
      if (project.status.showAlert) {
        if (project.status.isInternal) {
          project.status.showAlert = false
        }
      }
      return project
    })
  }

  resetFilterAccountName() {
    this.filters.accountName = ""
  }

  resetFilterType() {
    this.filters.type = ""
  }

  resetFilterReferenceNumber() {
    this.filters.referenceNumber = ""
  }

  resetFilterSensusName() {
    this.filters.sensusName = ""
  }

  resetFilterState() {
    this.filters.state = []
  }

  resetFilterVarName() {
    this.filters.varName = ""
  }

  resetFilters() {
    // FIXME: A bug in Vueitfy (vuetifyjs/vuetify/issues/4144) means we need individual callbacks for all clearable fields
    // This is supposed to be fixed in v2.0.0 which is scheduled to release early June 2019
    this.resetFilterAccountName()
    this.resetFilterReferenceNumber()
    this.resetFilterType()
    this.resetFilterState()
    this.resetFilterSensusName()
    this.resetFilterVarName()
  }

  async mounted() {
    this.states = _.sortBy(_.map(await this.appService.getAllStates(), state => state.name))
    this.statusIds = await this.engagementService.getProjectStatusIds()

    let filterSetMap: { [k: string]: string } = {
      type: "type",
      customer_contact_name: "customerName",
      distributor_contact_name: "distributorName",
      sensus_contact_name: "sensusName",
    }
    this.filterSets = _.mapKeys(await this.engagementService.getFilterSets(), (value: any, key: string) => {
      return filterSetMap[key]
    })

    let filters = _.clone(this.storeFilters)
    this.filters = filters
  }
}
